import React from 'react';
import classes from './Banner.module.css';
import era from '../../assets/image/era.jpg'; 

const Banner = () => {
  return (
    <div className={classes.banner}>
      <div className={classes.container}>
        <div className={classes.bannerContent}>
          <div className={classes.taglineContainer}>
            <h1 className={classes.tagline}>SPEAK CONFIDENTLY, SUCCEED BRILLIANTLY!</h1>
          </div>
          <div className={classes.imageContainer}>
            <img src={era} alt="Tutor" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

