import React from 'react';
import classes from './PublicFooter.module.css';
import Logo from '../../../assets/image/logo/navbar.png';

import PhoneIcon from '../../../assets/icons/phone.png';
import EmailIcon from '../../../assets/icons/email.png';
import FacebookIcon from '../../../assets/icons/facebook.png';
import InstagramIcon from '../../../assets/icons/instagram.png';
import YouTubeIcon from '../../../assets/icons/youtube.png';
import TikTokIcon from '../../../assets/icons/tiktok.png';

const PublicFooter = () => {
    return (
        <div className={classes['footer-section']}>
            <img src={Logo} alt='Logo' className={classes['logo-footer']} />
            <div className={classes['contact-details']}>
                <a href="tel:+639171165620" target="_blank" rel="noopener noreferrer">
                    <img src={PhoneIcon} alt='Phone' className={classes['contact-icon']} />
                    0917 116 5620
                </a>
                <a href="mailto:verbalablangaugetutorial@gmail.com" target="_blank" rel="noopener noreferrer">
                    <img src={EmailIcon} alt='Email' className={classes['contact-icon']} />
                    verbalablangaugetutorial@gmail.com
                </a>
            </div>
            <div className={classes['social-icons']}>
                <a href="https://www.facebook.com/Verbalab143" target="_blank" rel="noopener noreferrer">
                    <img src={FacebookIcon} alt='Facebook' className={classes['social-icon']} />
                </a>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                    <img src={InstagramIcon} alt='Instagram' className={classes['social-icon']} />
                </a>
                <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                    <img src={YouTubeIcon} alt='YouTube' className={classes['social-icon']} />
                </a>
                <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                    <img src={TikTokIcon} alt='TikTok' className={classes['social-icon']} />
                </a>
            </div>     
        </div>
    );
};

export default PublicFooter;
