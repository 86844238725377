import React from 'react';
import classes from './StudentShowcase.module.css';
import class1 from '../../assets/screenshots/class1.jpg'; 
import class2 from '../../assets/screenshots/class2.jpg';
import class3 from '../../assets/screenshots/class3.jpg'; 

const StudentShowcase = () => {
  return (
    <div className={classes.showcase}>
      <h2>Our Students in Action</h2>
      <div className={classes.grid}>
        <div className={classes.item}>
          <img src={class1} alt="Class 1" />
        </div>
        <div className={classes.item}>
          <img src={class2} alt="Class 2" />
        </div>
        <div className={classes.item}>
          <img src={class3} alt="Class 3" />
        </div>
      </div>
    </div>
  );
};

export default StudentShowcase;

