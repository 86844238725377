import React from 'react';
import classes from './Benefits.module.css';
import micIcon from '../../assets/icons/microphone.png'; 
import stairsIcon from '../../assets/icons/stairs.png'; 
import bulbIcon from '../../assets/icons/bulb.png'; 

const Benefits = () => {
  return (
    <div className={classes.benefits}>
      <div className={classes.benefitItem}>
        <img src={micIcon} alt="Mic Icon" className={classes.icon} />
        <p>Transform stage fright <span className={classes.highlight}>into</span> stage presence.</p>
      </div>
      <div className={classes.benefitItem}>
        <img src={stairsIcon} alt="Stairs Icon" className={classes.icon} />
        <p>Empower yourself <span className={classes.highlight}>with</span> powerful communication skills.</p>
      </div>
      <div className={classes.benefitItem}>
        <img src={bulbIcon} alt="Bulb Icon" className={classes.icon} />
        <p>Think on your feet <span className={classes.highlight}>and</span> speak spontaneously.</p>
      </div>
    </div>
  );
};

export default Benefits;


