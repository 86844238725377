import React, { useState } from 'react';
import classes from './Programs.module.css';

const Programs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const programs = [
    {
      title: "Public Speaking Mastery",
      description: "From Basics to Brilliance",
      details: `Develop your public speaking skills techniques for speech preparation, delivery, and audience engagement.`,
      price: "₱2,000.00",
      schedules: [
        {
          date: "July 8 - 19 (2 hours per day, 2 weeks)",
          sessions: [
            "Elementary: 10:00 AM - 12:00 PM",
            "High School: 1:00 PM - 3:00 PM",
            "Adult: 6:00 PM - 8:00 PM"
          ]
        },
        {
          date: "July 6 - 27 (4 hours per day, 5 Saturdays)",
          sessions: [
            "High School/Adult: 8:00 AM - 12:00 PM"
          ]
        },
        {
          date: "July 22 - August 2 (2 hours per day, 2 weeks)",
          sessions: [
            "Elementary: 10:00 AM - 12:00 PM",
            "High School: 1:00 PM - 3:00 PM",
            "Adult: 6:00 PM - 8:00 PM"
          ]
        },
        {
          date: "August 3 - 24 (4 hours per day, 5 Saturdays)",
          sessions: [
            "High School/Adult: 8:00 AM - 12:00 PM"
          ]
        }
      ]
    },
    {
      title: "BPO Career Preparation",
      description: "BPO Readiness",
      details: `Communication and Career Skills for Success
Equip yourself with the communication, customer service, and technical skill needed for a successful career in the BPO industry.`,
      price: "₱0.00",
      schedules: ["Schedules: Coming Soon"]
    },
    {
      title: "English for Non-English Speakers",
      description: "English Language Mastery",
      details: `From Basics to Fluency
Gain comprehensive English skill from basic grammar to advanced communication and cultural understanding.`,
      price: "₱0.00",
      schedules: ["Schedules: Coming Soon"]
    }
  ];

  return (
    <div className={classes.container} id="programs-section">
      <h1>Our Programs</h1>
      {programs.map((program, index) => (
        <div key={index} className={classes.accordionItem}>
          <button className={classes.accordionButton} onClick={() => toggleAccordion(index)}>
            {program.title}
          </button>
          {activeIndex === index && (
            <div className={classes.accordionContent}>
                <h3>{program.description}</h3>
                <p>{program.details}</p>
                <h4>Price: {program.price}</h4>
                <h4>Schedules:</h4>
                {program.schedules.map((schedule, i) => (
                <div key={i} className={classes.schedule}>
                    <strong>{schedule.date || schedule}</strong>
                    {schedule.sessions && schedule.sessions.map((session, j) => (
                    <p key={j}>{session}</p>
                    ))}
                </div>
                ))}
                {program.schedules.some(schedule => schedule.sessions) && (
                <button className={classes.enrollButton}>Enroll Now</button>
                )}
            </div>
            )}
          
        </div>
      ))}
    </div>
  );
};

export default Programs;
