import React from 'react';
import Banner from '../Banner/Banner';
import StudentShowcase from '../StudentShowcase/StudentShowcase';
import Speech from '../Speech/Speech';
import Benefits from '../Benefits/Benefits';
import Features from '../Features/Features';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import classes from './GetStarted.module.css';

const GetStarted = () => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h1>Become a Public Speaking Pro</h1>
        <p>Conquer Your Fears, Boost Your Confidence, and Master Spontaneous Speaking!</p>
      </div>
      <Banner />
      <StudentShowcase />
      <Speech />
      <Benefits />
      <Features />
      <PublicFooter />
      <div className={classes.floatingButton}>
        <span className={classes.handIcon}>👉</span>
        ENROLL NOW
      </div>
    </div>
  );
};

export default GetStarted;
