import React, { useContext, useState, useEffect, useRef  } from 'react';
import classes from './PublicHeader.module.css';
import Logo from '../../../assets/image/logo/navbar.png';
import { postData, fetchData } from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';

import { saveToLocalStorage } from '../../../helpers/localStorageHelper';
import { formatCurrency } from '../../../utils/currencyUtils';
import Slider from 'react-slick'; 
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import UserContext from '../../../contexts/UserContext';


const PublicHeader = () => {

  const [isFloating, setIsFloating] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const showWarning = (message) => {
    toast.warn(message);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Set the header to float when the user scrolls down
      setIsFloating(window.scrollY > 0);
    };

    // Add the event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  // Smooth scroll function
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 90,
        behavior: 'smooth',
      });
    }

    setIsMenuOpen(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };


  

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  return (
    <div className={`${classes.header} ${isFloating ? classes.floating : ''}`}>
      <img src={Logo} alt='Logo' className={classes.logo} />
      <button className={classes.burger} onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`${classes.nav} ${isMenuOpen ? classes.open : ''}`}>
        <ul className={classes.navList}>
          <li className={classes.navItem} onClick={() => scrollToSection('welcome-section')}>Welcome</li>
          <li className={classes.navItem} onClick={() => scrollToSection('programs-section')}>Programs</li>
          <li className={classes.navItem} onClick={() => scrollToSection('aboutus-section')}>About Us</li>
          <li className={classes.navItem} onClick={() => scrollToSection('support-section')}>Support</li>
          <li className={classes.navItem} onClick={() => scrollToSection('contact-section')}>Contact</li>
        </ul>
      </nav>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default PublicHeader;

