import React from 'react';
import classes from './Features.module.css';
import workshopIcon from '../../assets/icons/workshop.png';
import phoneIcon from '../../assets/icons/mobile.png';
import instructorIcon from '../../assets/icons/instructor.png';
import feedbackIcon from '../../assets/icons/feedback.png'; 

const Features = () => {
  return (
    <div className={classes.features}>
      <div className={classes.featureItem}>
        <img src={workshopIcon} alt="Interactive Workshops" className={classes.icon} />
        <p>Interactive Workshops</p>
      </div>
      <div className={classes.featureItem}>
        <img src={instructorIcon} alt="Expert Instructors" className={classes.icon} />
        <p>Expert Instructors</p>
      </div>
      <div className={classes.featureItem}>
        <img src={phoneIcon} alt="Convenience and Accessibility" className={classes.icon} />
        <p>Convenience and Accessibility</p>
      </div>
      <div className={classes.featureItem}>
        <img src={feedbackIcon} alt="Personalized Feedback" className={classes.icon} />
        <p>Personalized Feedback</p>
      </div>
    </div>
  );
};

export default Features;

