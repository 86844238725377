import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import Programs from '../Programs/Programs';

import classes from './Homepage.module.css';

import fbCommentScreenshot1 from '../../assets/screenshots/fbCommentScreenshot1.jpg';
import fbCommentScreenshot2 from '../../assets/screenshots/fbCommentScreenshot2.jpg';
import fbCommentScreenshot3 from '../../assets/screenshots/fbCommentScreenshot3.jpg';
import class4 from '../../assets/screenshots/class4.jpg';

const Homepage = () => {

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 90,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={classes.container}>
      <PublicHeader />

      {/* Hero Section */}
      <section className={classes.heroSection} id='welcome-section'>
        <h1>Welcome to Verbalab!</h1>
        <p>Unlock your potential with our personalized language tutorials.</p>
        <div className={classes.ctaButtons}>
          <button>Get Started</button>
          <button onClick={() => scrollToSection('programs-section')}>Explore Courses</button>
          <button>Sign Up Now</button>
        </div>
      </section>

      {/* Introduction */}
      <section className={classes.introduction}>
        <h2>Welcome to Verbalab</h2>
        <p>We're dedicated to helping you master new languages through engaging and effective tutorials. Our expert tutors are here to guide you every step of the way.</p>
        <h3>Our Mission</h3>
        <p>At Verbalab, we aim to provide high-quality language education that empowers individuals to communicate confidently in any language they choose.</p>
      </section>

      {/* Key Features and Benefits */}
      <section className={classes.featuresBenefits}>
        <h2>Why Choose Verbalab?</h2>
        <div className={classes.features}>
          <div className={classes.feature}>
            <h3>Personalized Lessons</h3>
            <p>Get tailored lessons that fit your learning style and pace.</p>
          </div>
          <div className={classes.feature}>
            <h3>Expert Tutors</h3>
            <p>Learn from experienced tutors who are passionate about teaching.</p>
          </div>
          <div className={classes.feature}>
            <h3>Flexible Scheduling</h3>
            <p>Enjoy the convenience of scheduling lessons at times that work best for you.</p>
          </div>
          <div className={classes.feature}>
            <h3>Interactive Learning</h3>
            <p>Engage in interactive sessions that make learning fun and effective.</p>
          </div>
        </div>
      </section>

      <Programs />
      
      {/* Testimonials */}
      <section className={classes.testimonials}>
        <h2>What Our Students Say</h2>
        <div className={classes.fbComments}>
          <img src={fbCommentScreenshot1} alt="Student comment screenshot 1" />
          <img src={fbCommentScreenshot2} alt="Student comment screenshot 2" />
          <img src={fbCommentScreenshot3} alt="Student comment screenshot 3" />
        </div>
      </section>

      {/* How It Works */}
      <section className={classes.howItWorks}>
        <h2>How It Works</h2>
        <div className={classes.steps}>
          <div className={classes.step}>
            <h3>Discover Your Path</h3>
            <p>Choose a program tailored to your unique learning style and schedule.</p>
          </div>

          <div className={classes.step}>
            <h3>Join Us</h3>
            <p>Complete your payment and start the course.</p>
          </div>

          <div className={classes.step}>
            <h3>Begin Your Journey</h3>
            <p>Start your personalized lessons and watch your progress soar!</p>
          </div>
        </div>
      </section>


      {/* Visual Elements */}
      <section className={classes.visualElements}>
        <h2>See Our Tutors in Action</h2>
        <div className={classes.media}>
          <img src={class4} alt="Tutors in action" className={classes.image} />
          <iframe
            src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FVerbalab143%2Fvideos%2F482309520918857%2F&show_text=false&width=560&t=0"
            width="560"
            height="314"
            className={classes.video}
            scrolling="no"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </section>




      
      <PublicFooter />
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
};

export default Homepage;
