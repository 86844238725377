import React from 'react';
import classes from './Speech.module.css';
import person from '../../assets/image/speech.webp'; 

const Speech = () => {
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={person} alt="Public Speaking" />
        <div className={classes.overlay}></div>
        <div className={classes.label}>
          Master Public Speaking: Overcome Fear, Build Confidence, and Excel in Impromptu Speaking!
        </div>
      </div>
    </div>
  );
};

export default Speech;

